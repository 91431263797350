import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/syeda/corvette/src/components/post-layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Can you really transform C8 Corvette into A V12-Powered Cadillac Supercar?`}</h1>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1012px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/a34b92908be82d2edf781171a26e5213/50055/cadillac.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "51%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAKABQDASIAAhEBAxEB/8QAGAAAAgMAAAAAAAAAAAAAAAAAAAUCAwT/xAAUAQEAAAAAAAAAAAAAAAAAAAAA/9oADAMBAAIQAxAAAAFhCtaPjAH/xAAaEAACAgMAAAAAAAAAAAAAAAABAgMyABEU/9oACAEBAAEFAmnAPSMSQMHA01oqf//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQMBAT8BP//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQIBAT8BP//EABkQAQEBAAMAAAAAAAAAAAAAAAEAERASMf/aAAgBAQAGPwJAVs6u23nBf//EABoQAQACAwEAAAAAAAAAAAAAAAEAIRExQVH/2gAIAQEAAT8hNDq1BGnwMwN7y5cYZmiJCJ//2gAMAwEAAgADAAAAEDDP/8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAwEBPxA//8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAgEBPxA//8QAGxABAAMBAQEBAAAAAAAAAAAAAQARITFxUZH/2gAIAQEAAT8QbONqxfs0pMAa/Oy7h0ap+xTSIta2NAFDeeRCEDAHk//Z')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "cadillac",
            "title": "cadillac",
            "src": "/static/a34b92908be82d2edf781171a26e5213/50055/cadillac.jpg",
            "srcSet": ["/static/a34b92908be82d2edf781171a26e5213/f93b5/cadillac.jpg 300w", "/static/a34b92908be82d2edf781171a26e5213/b4294/cadillac.jpg 600w", "/static/a34b92908be82d2edf781171a26e5213/50055/cadillac.jpg 1012w"],
            "sizes": "(max-width: 1012px) 100vw, 1012px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p>{`Can you really transform a C8 Corvette into a V12-powered Cadillac supercar? That's the question on many enthusiasts' minds. While electric motors deliver unparalleled acceleration, they're not as smooth and powerful as a V8. So, is it possible to turn a C8 into a supercar? This blog post will try to answer that question.`}</p>
    <p>{`This question was answered when Chevrolet employee Zora Arkus-Duntov, a veteran engineer and designer, posted CAD images of a mid-engine Corvette. The CAD pictures showed the engine and substructure of the car. The engineering team aimed to avoid breaching the central tunnel. The results are still unknown, but it's easy to imagine that they might have succeeded in achieving their goal.`}</p>
    <p>{`This dream vehicle could be the XLR version of the Cadillac CT5. Regardless, the mid-engine model could be a hybrid of both. In the mid-engine Cadillac, the 7.5-liter V12 engine produced 750 horsepower and 450 pound-feet of torque. No production Cadillac has used that engine, but a mid-engine version based on the C8 Corvette may be in the cards by 2020. It could use the XLR nameplate. As for the possibility of a V12-powered Cadillac supercar, it's difficult to say.`}</p>
    <p>{`Competition Carbon is a company that specialize in Lamborghinis and Corvettes, and they want to transform a C8 Corvette into a Cadillac supercar. The company plans to reveal the concept at SEMA 2022. The concept features a beautiful body kit that changing the overall appearance of the Corvette. The front end has an aggressive front splitter and a carbon-fiber hood. The car's body kit has a striking front fascia with vertical LED daytime running lights and a carbon-fiber splitter.`}</p>
    <p>{`The C8 Corvette is already an impressive luxury car. Some people even claim it's the best Corvette ever. But can you really transform a V12-powered supercar into a V8-powered Cadillac? You must know the basics. If you're not sure, you can always build a custom XLR8. If you're feeling adventurous, you can also turn it into a supercar.`}</p>
    <p>{`A mid-engine Corvette has been a dream for the past 50 years. It's been a long time project for the company. As the first model to be revealed at SEMA 2022, the project isn't quite ready yet. In addition to a new body kit, the mid-engine Cadillac supercar will still keep its roof and doors. The entire car's front end will have a carbon-fiber splitter and vertical LED daytime running lights.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      